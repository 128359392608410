@keyframes rotate { 	
	from {
		transform: rotate(0deg)
		           translate(-10px)
		           rotate(0deg);
	}
	to {
		transform: rotate(360deg)
		           translate(-10px) 
		           rotate(-360deg);
	}
}
