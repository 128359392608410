.pagination {
	.page-item {
		a.page-link {
			border: none;
			border-bottom: 0px;
			color: $grey-400;
			padding: .5rem .9rem;
			&:hover,
			&:focus {
				background-color: transparent;
				color: $primary;
				border-bottom: 0px;
				box-shadow: none;
			}
		}
		&.active {
			a.page-link {
			background-color: $primary;
			border-radius: .25rem;
			box-shadow: 0px 3px 5px rgba($primary, 0.2);
			color: $white;
			}
		}
	}
	
	&.pagination-lg {
		a.page-link {
			padding: .6rem 1.2rem;
		}
	}
	&.pagination-sm {
		a.page-link {
			padding: .2rem .6rem;
		}
	}
}