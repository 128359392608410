@import '~bootstrap/dist/css/bootstrap.css';
@import '~assets/scss/lazy.scss';
@import '~assets/scss/demo.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
