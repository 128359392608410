// // Original styles
// .noUi-target,
// .noUi-target * {
//     -webkit-touch-callout: none;
//     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//     -webkit-user-select: none;
//     touch-action: none;
//     user-select: none;
//     box-sizing: border-box;
// }
//
// .noUi-target {
//     position: relative;
//     direction: ltr;
// }
//
// .noUi-base,
// .noUi-connects {
//     width: 100%;
//     height: 100%;
//     position: relative;
//     z-index: 1;
// }
//
// /* Wrapper for all connect elements.
//  */
//
// .noUi-connects {
//     overflow: hidden;
//     z-index: 0;
// }
//
// .noUi-connect,
// .noUi-origin {
//     will-change: transform;
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     transform-origin: 0 0;
// }
//
//
// html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
//     left: auto;
//     right: 0;
// }
//
// .noUi-vertical .noUi-origin {
//     width: 0;
// }
//
// .noUi-horizontal .noUi-origin {
//     height: 0;
// }
//
// .noUi-handle {
//     position: absolute;
// }
//
// .noUi-state-tap .noUi-connect,
// .noUi-state-tap .noUi-origin {
//     transition: transform .3s;
// }
//
// .noUi-state-drag * {
//     cursor: inherit !important;
// }
//
// .noUi-horizontal {
//     height: $noui-target-thickness;
// }
//
// .noUi-horizontal .noUi-handle {
//     width: 34px;
//     height: 28px;
//     left: -17px;
//     top: -6px;
// }
//
// .noUi-vertical {
//     width: $noui-target-thickness;
// }
//
// .noUi-vertical .noUi-handle {
//     width: 28px;
//     height: 34px;
//     left: -6px;
//     top: -17px;
// }
//
// html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
//     right: -17px;
//     left: auto;
// }
//
// .noUi-connects {
//     border-radius: 3px;
// }
//
// .noUi-connect {
//     background: $noui-slider-connect-bg;
// }
//
// .noUi-draggable {
//     cursor: ew-resize;
// }
//
// .noUi-vertical .noUi-draggable {
//     cursor: ns-resize;
// }
//
// .noUi-handle {
//     border: 1px solid #D9D9D9;
//     border-radius: 3px;
//     background: #FFF;
//     cursor: default;
//     box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
//     outline: none;
// }
//
// .noUi-active {
//     outline: none;
//     //box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
// }
//
//
// /* Disabled state;
//  */
//
// [disabled] .noUi-connect {
//     background: #B8B8B8;
// }
//
// [disabled].noUi-target,
// [disabled].noUi-handle,
// [disabled] .noUi-handle {
//     cursor: not-allowed;
// }
//
//
// /* Base;
//  *
//  */
//
// .noUi-pips,
// .noUi-pips * {
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }
//
// .noUi-pips {
//     position: absolute;
//     color: #999;
// }
//
//
// /* Values;
//  *
//  */
//
// .noUi-value {
//     position: absolute;
//     white-space: nowrap;
//     text-align: center;
// }
//
// .noUi-value-sub {
//     color: #ccc;
//     font-size: 10px;
// }
//
//
// /* Markings;
//  *
//  */
//
// .noUi-marker {
//     position: absolute;
//     background: #CCC;
// }
//
// .noUi-marker-sub {
//     background: #AAA;
// }
//
// .noUi-marker-large {
//     background: #AAA;
// }
//
//
// /* Horizontal layout;
//  *
//  */
//
// .noUi-pips-horizontal {
//     padding: 10px 0;
//     height: 80px;
//     top: 100%;
//     left: 0;
//     width: 100%;
// }
//
// .noUi-value-horizontal {
//     transform: translate(-50%, 50%);
// }
//
// .noUi-rtl .noUi-value-horizontal {
//     transform: translate(50%, 50%);
// }
//
// .noUi-marker-horizontal.noUi-marker {
//     margin-left: -1px;
//     width: 2px;
//     height: 5px;
// }
//
// .noUi-marker-horizontal.noUi-marker-sub {
//     height: 10px;
// }
//
// .noUi-marker-horizontal.noUi-marker-large {
//     height: 15px;
// }
//
//
// /* Vertical layout;
//  *
//  */
//
// .noUi-pips-vertical {
//     padding: 0 10px;
//     height: 100%;
//     top: 0;
//     left: 100%;
// }
//
// .noUi-value-vertical {
//     -webkit-transform: translate(0, -50%);
//     transform: translate(0, -50%, 0);
//     padding-left: 25px;
// }
//
// .noUi-rtl .noUi-value-vertical {
//     -webkit-transform: translate(0, 50%);
//     transform: translate(0, 50%);
// }
//
// .noUi-marker-vertical.noUi-marker {
//     width: 5px;
//     height: 2px;
//     margin-top: -1px;
// }
//
// .noUi-marker-vertical.noUi-marker-sub {
//     width: 10px;
// }
//
// .noUi-marker-vertical.noUi-marker-large {
//     width: 15px;
// }
//
// .noUi-tooltip {
//     display: block;
//     position: absolute;
//     border: 1px solid #D9D9D9;
//     border-radius: 3px;
//     background: #fff;
//     color: #000;
//     padding: 5px;
//     text-align: center;
//     white-space: nowrap;
// }
//
// .noUi-horizontal .noUi-tooltip {
//     -webkit-transform: translate(-50%, 0);
//     transform: translate(-50%, 0);
//     left: 50%;
//     bottom: 120%;
// }
//
// .noUi-vertical .noUi-tooltip {
//     -webkit-transform: translate(0, -50%);
//     transform: translate(0, -50%);
//     top: 50%;
//     right: 120%;
// }


//  Custom styles

.noUi-target {
    background: $noui-target-bg;
    border-radius: $noui-target-border-radius;
    border: 0;
    box-shadow: $noui-target-box-shadow;
    margin: 15px 0;
    cursor: pointer;
}

.noUi-horizontal {
    height: $noui-target-thickness;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -10px;
}

.noUi-vertical {
    width: $noui-target-thickness;
}

.noUi-connect {
    background: $primary;
    box-shadow: none;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
    top: -$noui-target-thickness;
    width: $noui-handle-width;
    height: $noui-handle-width;
    border-radius: 100%;
    box-shadow: none;
    cursor: pointer;
    background-color: $noui-handle-bg;
    border: $noui-handle-border;
    transition: box-shadow .15s, transform .15s;
}


.noUi-horizontal .noUi-handle.noUi-active,
.noUi-vertical .noUi-handle.noUi-active {
    transform: scale(1.2);
}

[disabled] .noUi-handle,
[disabled].noUi-origin {
    cursor: not-allowed;
}

/* Range slider value labels */
.range-slider-value {
    font-size: $font-size-xs;
    font-weight: 500;
    background-color: transparent;
    color: $primary;
    border: 1px solid rgba($primary, 0.4);
    border-radius: 1rem;
    padding: .4em .8em .3em .85em;
}

.range-slider-wrapper .upper-info {
    font-weight: 400;
    margin-bottom: 5px;
}

.input-slider-value-output {
    background: #333;
    color: #fff;
    padding: 4px 8px;
    position: relative;
    top: 12px;
    font-size: 11px;
    border-radius: 2px;
}

.input-slider-value-output:after {
    bottom: 100%;
    left: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #333;
    border-width: 4px;
    margin-left: -4px;
}

.input-slider-value-output.left:after {
    left: 10px;
    right: auto;
}

.input-slider-value-output.right:after {
    right: 10px;
    left: auto;
}

.noUi-handle {
  &:before,
  &:after {
    display: none;
  }
}
